'use client';

import { useLanguage } from '@repo/i18n';
import { Button } from '@repo/ui/components/ui/button';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@repo/ui/components/ui/card';
import { useRouter } from 'next/navigation';

export const WelcomeBody = () => {
  const { dictionary, lang } = useLanguage();

  const router = useRouter();

  return (
    <Card className='md:px-5 py-5 w-full h-full'>
      <CardHeader>
        <CardTitle>
          <div className='flex flex-row justify-between'>
            <div>{dictionary.welcome.label}</div>
          </div>
        </CardTitle>
      </CardHeader>
      <CardContent className='w-full h-full flex flex-col space-y-8 items-start'>
        <div className='md:text-5xl text-xl pt-5'>
          {dictionary.welcome.title}
        </div>
        <div className='font-light text-sm italic pb-36 md:text-xl'>
          {dictionary.welcome.subtitle}
        </div>
        <div className='flex flex-row justify-between space-x-6 w-full'>
          <Button onClick={() => router.push(`/${lang}/login`)} className=''>
            {dictionary.welcome.login}
          </Button>
          <Button className='' onClick={() => router.push(`/${lang}/register`)}>
            {dictionary.welcome.register}
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};
